<template>
  <div id="app">
    <a-spin :spinning="globalSpin" :tip="globalSpinTip">
      <router-view/>
    </a-spin>
  </div>
</template>

<script>
import {
  syncTaskListStorageStatusChange,
  syncTaskListStorageAddResult,
  syncTaskListStorageAddError
} from "@/utils/index.js";
import {detail, list} from "@/request/task.js";
import {taskIdIsWaitResponse, delWaitResponseTask} from "@/utils/storage.js";
import Bus from "@/utils/bus";

export default {
  name: "App",
  data() {
    return {
      waitResTaskId: [],
      pollingInterval: null,
      clearPolling: false,
      globalSpin:false,
      globalSpinTip:"Loading..."
    };
  },
  mounted() {
    this.pollingInterval = setInterval(this.pollingTask, 3 * 1000);

    Bus.$on("pollingInterval", () => {
      //如果定时器已经停止了才重启
      if (this.clearPolling) {
        this.pollingInterval = setInterval(this.pollingTask, 3 * 1000);
        this.clearPolling = false;
      }
    });

    Bus.$on("globalLoading",(option) => {
      const {bool,tip} = option;
      this.globalSpin = bool;
      this.globalSpinTip = tip;
    })
  },
  methods: {
    pollingTask() {
      //检查是否有任务，创建轮询
      this.waitResTaskId = taskIdIsWaitResponse();
      if (this.waitResTaskId !== false) {
        list({uids: this.waitResTaskId}).then((res) => {
          res.data.forEach(async (item) => {
            syncTaskListStorageStatusChange(item);

            switch (item.status) {
              case "Running": //运行中
                break;
              case "Successed": //如果已成功则获取结果
                const res = await detail({uid: item.uid});
                syncTaskListStorageAddResult(res.data);
                //移除wait_response_task列表
                delWaitResponseTask(item.uid);
                break;
              case "Failed":
                const err = await detail({uid: item.uid});
                syncTaskListStorageAddError(err.data)
                //移除wait_response_task列表
                delWaitResponseTask(item.uid);
                break;
            }

            //改变异步任务列表表格数据
            setTimeout(() => {
              Bus.$emit("taskListData");
            }, 100);
          });
        });
      } else {
        clearInterval(this.pollingInterval);
        this.clearPolling = true;
      }
    },
  },
};
</script>

<style lang="less">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.charts-list,
.pdf-list {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  flex-wrap: wrap;

  .pdf {
    width: 40%;
  }

  .chart-title {
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    color: #464646;
  }
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0;
}

.ant-popover {
  .ant-form {
    .ant-form-item {
      display: flex;
      margin-bottom: 5px;
      justify-content: space-around;
    }
  }

  .ant-popover-inner-content {
    //height: 25rem;
    overflow: auto;
  }
}

.ant-table td { white-space: nowrap; }
</style>
