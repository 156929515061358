import Vue from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.less';
import * as echarts from 'echarts';
import "@/assets/style/reset.less";

Vue.config.productionTip = false;
Vue.use(Antd);
Vue.prototype.$echarts = echarts;

import $common from "@/utils/common.js";
Vue.prototype.common=$common;

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
