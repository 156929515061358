import Vue from 'vue'
import VueRouter from 'vue-router';
import Bus from "@/utils/bus";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Layout',
        component: () => import('../views/Layout.vue'),
        children: [
            {
                path: "/",
                name: "LayoutHome",
                component: () => import('../views/layout/Home.vue'),
                meta: {
                    title: "Home",
                    breadcrumb: [
                        {
                            name: 'Home',
                            path: '/'
                        }
                    ]
                }
            },
            {//mRNA 差异分析
                path: "/diff-expression/mRNA-Seq",
                name: "LayoutDiffExpressionMRNASeq",
                component: () => import('../views/diff-expression/mRNASeq.vue'),
                meta: {
                    title: "mRNA-Seq",
                    breadcrumb: [
                        {
                            name: 'mRNA-Seq',
                            path: '/diff-expression/mRNA-Seq'
                        }
                    ]
                }
            },
            {//microRNA 差异分析
                path: "/diff-expression/microRNA-Seq",
                name: "LayoutDiffExpressionMicroRNASeq",
                component: () => import('../views/diff-expression/microRNASeq.vue'),
                meta: {
                    title: "microRNA-Seq",
                    breadcrumb: [
                        {
                            name: 'microRNA-Seq',
                            path: '/diff-expression/microRNA-Seq'
                        }
                    ]
                }
            },
            {//查询->Expression OverView -> Single Gene
                path: "/expression_overview/single_gene",
                name: "LayoutExpressionOverViewSingleGene",
                component: () => import('../views/expression-overview/SingleGene.vue'),
                meta: {
                    title: "Single Gene",
                    breadcrumb: [
                        {
                            name: 'Single Gene',
                            path: '/expression_overview/single_gene'
                        }
                    ]
                }
            },
            {//查询->Expression OverView -> Multiple Gene
                path: "/expression_overview/multiple_gene",
                name: "LayoutQueryExpressionOverViewMultipleGene",
                component: () => import('../views/expression-overview/MultipleGene.vue'),
                meta: {
                    title: "Multiple Gene",
                    breadcrumb: [
                        {
                            name: 'Multiple Gene',
                            path: '/expression_overview/multiple_gene'
                        }
                    ]
                }
            },
            {//查询->Expression OverView -> micRNA expression
                path: "/expression_overview/mic_rna_expression",
                name: "LayoutExpressionOverViewMicRNAExpression",
                component: () => import('../views/expression-overview/micRNA/Expression.vue'),
                meta: {
                    title: "MicRNA Expression",
                    breadcrumb: [
                        {
                            name: 'MicRNA Expression',
                            path: '/expression_overview/mic_rna_expression'
                        }
                    ]
                }
            },
            {//查询->WGCNA
                path: "/WGCNA",
                name: "LayoutWGCNA",
                component: () => import('../views/wgcna/WGCNA.vue'),
                meta: {
                    title: "Co-Expression",
                    breadcrumb: [
                        {
                            name: 'Co-Expression',
                            path: '/WGCNA'
                        }
                    ]
                }
            },
            {//查询->WGCNA->查询结果
                path: "/WGCNAQueryResult",
                name: "LayoutWGCNAQueryResult",
                component: () => import('../views/wgcna/result/QueryResultNew.vue'),
                meta: {
                    title: "analysis",
                    breadcrumb: [
                        {
                            name: 'Co-Expression',
                            path: '/WGCNA'
                        }, {
                            name: "Co-Expression Query Result",
                            path: "/WGCNAQueryResult"
                        }
                    ]
                }
            },
            {//查询->miRNA-Seq WGCNA
                path: "/miRNA-WGCNA",
                name: "LayoutMicRNAWGCNA",
                component: () => import('../views/wgcna/MicRNA-WGCNA.vue'),
            },
            {//查询->miRNA-Seq WGCNA->查询结果
                path: "/miRNA-WGCNAQueryResult",
                name: "LayoutMicWGCNAQueryResult",
                component: () => import('../views/wgcna/result/MicRNA-QueryResult.vue'),
            },
            {//查询->RNA-array WGCNA
                path: "/RNA-array-WGCNA",
                name: "LayoutRNAArrayWGCNA",
                component: () => import('../views/wgcna/RNA-Array-WGCNA.vue'),
            },
            {//查询->RNA-array WGCNA->查询结果
                path: "/RNA-array-WGCNAQueryResult",
                name: "LayoutRNAArrayWGCNAQueryResult",
                component: () => import('../views/wgcna/result/RNA-Array-QueryResult.vue'),
            },
            {
                path: "/miRNA_target_search",
                name: "LayoutMicRNATargetSearch",
                component: () => import('../views/layout/MicRNATargetSearch.vue'),
                meta: {
                    title: "miRNA Target search",
                    breadcrumb: [
                        {
                            name: 'miRNA Target search',
                            path: '/miRNA_target_search'
                        }
                    ]
                }
            },
            {
                path: "/miRNA_target_search_result",
                name: "LayoutMicRNATargetSearchResult",
                component: () => import('../views/miRNA/MicRNATargetSearchResult.vue'),
                meta: {
                    title: "miRNA Target search result",
                    breadcrumb: [
                        {
                            name: 'miRNA Target search result',
                            path: '/miRNA_target_search_result'
                        }
                    ]
                }
            },
            {
                path: "/help",
                name: "LayoutHelp",
                component: () => import('../views/layout/Help.vue'),
                meta: {
                    title: "Help",
                    breadcrumb: [
                        {
                            name: 'Help',
                            path: '/help'
                        }
                    ]
                }
            },
            {//数据集-> 样本信息
                path: "/sample_info",
                name: "LayoutDataSetSampleInfo",
                component: () => import('../views/data-set/SampleInfo.vue'),
                meta: {
                    title: "样本信息",
                    breadcrumb: [
                        {
                            name: '数据集',
                            path: '/dataset'
                        },
                        {
                            name: "样本信息",
                            path: '/sample_info'
                        }
                    ]
                }
            },
            {//异步任务列表
                path: "/asyn_task_list",
                name: "LayoutAsynTaskList",
                component: () => import('../views/query/AsynTaskList.vue'),
                meta: {
                    title: "Asynchronous task",
                    breadcrumb: [
                        {
                            name: "Asynchronous task",
                            path: '/asyn_task_list'
                        }
                    ]
                }
            },
            {//异步任务列表-> 差异分析
                path: "/query_result",
                name: "LayoutQueryResult",
                component: () => import('../views/query/QueryResult.vue'),
                meta: {
                    title: "Differential Expression Result",
                    breadcrumb: [
                        {
                            name: "Asynchronous task",
                            path: '/asyn_task_list'
                        },
                        {
                            name: "Differential Expression Result",
                            path: '/query_result'
                        }
                    ]
                }
            },
            {//异步任务列表-> micRNA 差异分析
                path: "/micRNA_query_result",
                name: "LayoutMicRNAQueryResult",
                component: () => import('../views/query/micRNA/QueryResult.vue'),
                meta: {
                    title: "MicRNA Differential Expression Result",
                    breadcrumb: [
                        {
                            name: "Asynchronous task",
                            path: '/asyn_task_list'
                        },
                        {
                            name: "MicRNA Differential Expression Result",
                            path: '/micRNA_query_result'
                        }
                    ]
                }
            },
            {//异步任务列表-> 富集分析
                path: "/enrichment_analysis",
                name: "LayoutEnrichmentAnalysis",
                component: () => import('../views/query/EnrichmentAnalysis.vue'),
                meta: {
                    title: "Enrichment Analysis Result",
                    breadcrumb: [
                        {
                            name: "Asynchronous task",
                            path: '/asyn_task_list'
                        },
                        {
                            name: "Enrichment Analysis Result",
                            path: '/enrichment_analysis'
                        }
                    ]
                }
            },
            {//异步任务列表-> micRNA-富集分析
                path: "/micRNA_enrichment_analysis",
                name: "LayoutMicRNAEnrichmentAnalysis",
                component: () => import('../views/query/micRNA/EnrichmentAnalysis.vue'),
                meta: {
                    title: "MicRNA Enrichment Analysis Result",
                    breadcrumb: [
                        {
                            name: "Asynchronous task",
                            path: '/asyn_task_list'
                        },
                        {
                            name: "MicRNA Enrichment Analysis Result",
                            path: '/micRNA_enrichment_analysis'
                        }
                    ]
                }
            },
            {//异步任务列表-> 基因表达量
                path: "/gene_expression",
                name: "LayoutExpressionOverViewGeneExpression",
                component: () => import('../views/expression-overview/GeneExpression.vue'),
                meta: {
                    title: "Expression Abundance Result",
                    breadcrumb: [
                        {
                            name: "Asynchronous task",
                            path: '/asyn_task_list'
                        },
                        {
                            name: "Expression Abundance Result",
                            path: '/gene_expression'
                        }
                    ]
                }
            },
            {//异步任务列表-> 基因表达量(热图)
                path: "/gene_expression_hot_map",
                name: "LayoutExpressionOverViewGeneExpressionHotMap",
                component: () => import('../views/expression-overview/GeneExpressionHotMap.vue'),
                meta: {
                    title: "Expression Abundance Result(Heat map)",
                    breadcrumb: [
                        {
                            name: "Asynchronous task",
                            path: '/asyn_task_list'
                        },
                        {
                            name: "Expression Abundance Result(Heat map)",
                            path: '/gene_expression_hot_map'
                        }
                    ]
                }
            },
            {//异步任务列表-> 基因表达量V2
                path: "/gene_expression_micRNA",
                name: "LayoutExpressionOverViewGeneExpressionMicRNA",
                component: () => import('../views/expression-overview/micRNA/GeneExpression.vue'),
                meta: {
                    title: "MicRNA Expression Abundance Result",
                    breadcrumb: [
                        {
                            name: "Asynchronous task",
                            path: '/asyn_task_list'
                        },
                        {
                            name: "MicRNA Expression Abundance Result",
                            path: '/gene_expression_micRNA'
                        }
                    ]
                }
            },
        ]
    },
    {//页面刷新路由
        path: "/refresh",
        name: 'Refresh',
        component: () => import('../views/refresh.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

// 路由守卫
router.afterEach((to) => {
    Bus.$emit('menuSelect',to.path);
})

export default router
