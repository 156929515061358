import ajax,{request_v2} from './task_http';

//差异分析
export function submit_deseq2(params) {
    return ajax.post('/submit_deseq2', params)
}

//富集分析
export function submit_e4b(params) {
    return ajax.post('/submit_e4b', params)
}

//查询单个任务 参数字符串
export function detail(params) {
    return ajax.post('/detail', params)
}

//查询多个任务 参数数组
export function list(params) {
    return ajax.post('/list', params)
}

//查询基因表达量
export function submit_ea(params) {
    return ajax.post('/submit_ea', params)
}

// --------------------------- 二期 -------------------------------------
//差异分析
export function submit_deseq2_v2(params) {
    return request_v2.post('/submit_deseq2', params)
}

//查询基因表达量
export function submit_ea_v2(params) {
    return request_v2.post('/submit_ea', params)
}

//富集分析
export function submit_e4b_v2(params) {
    return request_v2.post('/submit_e4b', params)
}
