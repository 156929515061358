/**
 * 存储等待响应的异步任务信息
 * @param {string} type
 * @param {string} task_id
 * @param {string} meta
 * {
 *    task_id:{}
 * }
 */
export function waitResponseTask(type, task_id, meta) {
    let waitResponseTask = window.localStorage.getItem("wait_response_task");

    if (waitResponseTask === null) {
        waitResponseTask = {
            [task_id]:{
                create_time:new Date().getTime(),
                task_id:task_id,
                type:type,
                meta:meta
            }
        };
    } else {
        waitResponseTask = JSON.parse(waitResponseTask);
        waitResponseTask[task_id]={
            create_time:new Date().getTime(),
            task_id:task_id,
            type:type,
            meta:meta
        };
    }

    window.localStorage.setItem("wait_response_task", JSON.stringify(waitResponseTask));
}

/**
 * 检查是否存在等待响应的异步任务信息
 */
 export function taskIdIsWaitResponse() {
    let waitResponseTask = window.localStorage.getItem("wait_response_task");

    if (waitResponseTask === null) {
        return false;
    } else {
        waitResponseTask = JSON.parse(waitResponseTask);
        if(Object.keys(waitResponseTask).length !== 0){
            return Object.keys(waitResponseTask);
        }else{
            return false;
        }
    }
}

/**
 * 删除等待响应的某个异步任务信息
 * @param {string} task_id
 */
export function delWaitResponseTask(task_id){
    let waitResponseTask = JSON.parse(window.localStorage.getItem("wait_response_task"));
    delete waitResponseTask[task_id];
    window.localStorage.setItem("wait_response_task",JSON.stringify(waitResponseTask))
}