import * as echarts from 'echarts';

export default {
    api_url: "/api",
    task_url: "/task",
    task_url_v2: "/task_v2",
    source_url: "/static/",
    niu_api_url: "/prod-api",
    G2ChartTheme: {
        //colors10: ['#d87c7c','#919e8b', '#d7ab82',  '#6e7074','#61a0a8','#efa18d', '#787464', '#cc7e63', '#724e58', '#4b565b'],
        //colors20: ['#d87c7c','#919e8b', '#d7ab82',  '#6e7074','#61a0a8','#efa18d', '#787464', '#cc7e63', '#724e58', '#4b565b','#FF6B3B', '#626681', '#FFC100', '#9FB40F', '#76523B', '#DAD5B5', '#0E8E89', '#E19348', '#F383A2', '#247FEA'],
        "colors10": ["#FF4500", "#1AAF8B", "#406C85", "#F6BD16", "#B40F0F", "#2FB8FC", "#4435FF", "#FF5CA2", "#BBE800", "#FE8A26"],
        "colors20": ["#FF4500", "#1AAF8B", "#406C85", "#F6BD16", "#B40F0F", "#2FB8FC", "#4435FF", "#FF5CA2", "#BBE800", "#FE8A26", "#946DFF", "#6C3E00", "#6193FF", "#FF988E", "#36BCCB", "#004988", "#FFCF9D", "#CCDC8A", "#8D00A1", "#1CC25E"],
        "graphcolors20": ["#1AAF8B", "#2FB8FC", "#FF5CA2", "#BBE800", "#FF4500", "#FE8A26", "#946DFF", "#6C3E00", "#4435FF", "#6193FF", "#FF988E", "#36BCCB", "#004988", "#B40F0F", "#406C85", "#F6BD16", "#FFCF9D", "#CCDC8A", "#8D00A1", "#1CC25E"]
    },
    getColor20(index){
        if(index < 20){
            return this.G2ChartTheme.colors20[index];
        }else{
            return this.G2ChartTheme.colors20[index % 20];
        }
    },
    //判断是否是不展示的字段
    isNotShowColumns(text) {
        //表格和下拉选择需要隐藏的字段
        const notShowColumns = ["bioproject", 'biosample', "library_layout", "library_source", "assay_type", "organism", "platform", "library_name", "sra_study"];
        return notShowColumns.includes(text.toLowerCase())
    },
    getLocalStorageItem(itemName) {
        const item = window.localStorage.getItem(itemName);
        return item === null ? null : JSON.parse(item);
    },
    setLocalStorageItem(itemName, data) {
        JSON.stringify(window.localStorage.getItem(itemName, data));
    },
    //随机颜色
    randomColor() {
        return '#' + ('00000' + (Math.random() * 0x1000000 << 0).toString(16)).substr(-6);
    },
    //vw 转 px num
    vwToPxNum(vw) {
        let width = document.documentElement.clientWidth;
        let fontSize = width / 100;
        return vw * fontSize;
    },
    //vh 转 px num
    vhToPxNum(vh) {
        let height = document.documentElement.clientHeight;
        let fontSize = height / 100;
        return vh * fontSize;
    },
    /**
     * @desc 生成Ant design Vue 表格column
     * @param {Array} data 分组数据
     * @param {*} input 搜索的输入框，用于聚焦
     * @param {String} emptyTitle 空key 显示的Title
     */
    setColumns(data, input, emptyTitle) {
        let columns = [];
        Object.keys(data[0]).forEach((ele) => {
            if (ele === "__EMPTY") {
                columns.push({
                    title: emptyTitle,
                    dataIndex: "__EMPTY",
                    width: 180,
                    fixed: "left",
                    scopedSlots: {
                        filterDropdown: "filterDropdown",
                        filterIcon: "filterIcon",
                        customRender: "customRender",
                    },
                    onFilter: (value, record) =>
                        record.__EMPTY.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: (visible) => {
                        if (visible) {
                            setTimeout(() => {
                                input.focus();
                            }, 100);
                        }
                    },
                });
                return;
            }
            columns.push({
                title: ele,
                dataIndex: ele,
                // width: 110,
                customRender: (text, row, index) => {
                    if (!text) return "";
                    //判断是否是数字
                    return /^[0-9]+.?[0-9]*/.test(text) ? text.toFixed(3) : text;
                }
            });
        });
        return columns;
    },
    //深拷贝
    deepClone(target) {
        const map = new WeakMap()

        function isObject(target) {
            return (typeof target === 'object' && target) || typeof target === 'function'
        }

        function clone(data) {
            if (!isObject(data)) {
                return data
            }
            if ([Date, RegExp].includes(data.constructor)) {
                return new data.constructor(data)
            }
            if (typeof data === 'function') {
                return new Function('return ' + data.toString())()
            }
            const exist = map.get(data)
            if (exist) {
                return exist
            }
            if (data instanceof Map) {
                const result = new Map()
                map.set(data, result)
                data.forEach((val, key) => {
                    if (isObject(val)) {
                        result.set(key, clone(val))
                    } else {
                        result.set(key, val)
                    }
                })
                return result
            }
            if (data instanceof Set) {
                const result = new Set()
                map.set(data, result)
                data.forEach(val => {
                    if (isObject(val)) {
                        result.add(clone(val))
                    } else {
                        result.add(val)
                    }
                })
                return result
            }
            const keys = Reflect.ownKeys(data)
            const allDesc = Object.getOwnPropertyDescriptors(data)
            const result = Object.create(Object.getPrototypeOf(data), allDesc)
            map.set(data, result)
            keys.forEach(key => {
                const val = data[key]
                if (isObject(val)) {
                    result[key] = clone(val)
                } else {
                    result[key] = val
                }
            })
            return result
        }

        return clone(target)
    },
    //echarts 图表下载为图片
    echartsExportImg(options) {
        const {
            echartsId,
            name = "图表",
            pixelRatio = 3,
            backgroundColor = "#fff",
            excludeComponents = [ // 保存图表时忽略的工具组件,默认忽略工具栏
                'toolbox'
            ],
            type = 'png', // 图片类型支持png和jpeg
            slotMethods = {
                head: null
            }
        } = options;
        const convertBase64UrlToBlob = (base64) => {
            const parts = base64.dataURL.split(';base64,');
            const contentType = parts[0].split(':')[1];
            const raw = window.atob(parts[1]);
            const rawLength = raw.length;
            const uInt8Array = new Uint8Array(rawLength);
            for (let i = 0; i < rawLength; i++) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            return new Blob([uInt8Array], {
                type: contentType
            });
        };

        if (slotMethods.head !== null) {
            slotMethods.head();
            console.log("执行插槽方法")
        }

        const myChart = echarts.getInstanceByDom(
            document.getElementById(echartsId)
        );
        const url = myChart.getConnectedDataURL({
            pixelRatio: pixelRatio,
            backgroundColor: backgroundColor, // 图表背景色
            excludeComponents: excludeComponents,
            type: type, // 图片类型支持png和jpeg
        });
        const a = document.createElement('a');
        a.download = `${name}.png`;
        a.target = '_blank';
        a.href = url;
        // Chrome and Firefox
        if (typeof MouseEvent === 'function') {
            const evt = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false,
            });
            a.dispatchEvent(evt);
        } else {
            // IE
            const base64 = {
                dataURL: url,
                type: 'png',
                ext: 'png',
            };
            const blob = convertBase64UrlToBlob(base64);
            // 下载
            window.navigator.msSaveBlob(
                blob,
                `${name}.png`
            );
        }
    },
    //去除字符串空格等特殊字符
    replaceSpecialChar(text) {
        // const reg=/\\|\/|\s|\?|\？|\*|\"|\“|\”|\'|\‘|\’|\<|\>|\{|\}|\[|\]|\【|\】|\：|\:|\、|\^|\$|\!|\~|\`|\|/g;
        const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
        return text.replace(pattern, "");
    },
    /**
     * 去除对象数组无重复列的值
     * @param {Array<Object>} arr
     * @param {boolean} needReplaceSpecialChar 是否需要替换值中的特殊字符
     */
    removeNoRepetitionColumn(arr, needReplaceSpecialChar = true) {
        let aMap = {};
        arr.forEach((item, index) => {
            Object.keys(item).forEach(ite => {
                if (index === 0) {
                    aMap[ite] = [item[ite]];
                } else {
                    if (aMap[ite].includes(item[ite])) return;

                    aMap[ite].push(item[ite]);
                }
            })
        })

        let removeField = [];
        Object.keys(aMap).forEach(item => {
            if (aMap[item].length <= 1) {
                removeField.push(item)
            }
        })

        return arr.map((item, index) => {
            removeField.forEach(v => {
                delete item[v];
            })
            if (needReplaceSpecialChar) {
                Object.keys(item).forEach(k => {
                    item[k] = this.replaceSpecialChar(item[k])
                })
            }
            return item;
        })
    },
    /**
     * 动态引入js文件
     * @src 文件地址
     */
    loadJs(src) {
        //判断是否已经引入js
        function isInclude(name) {
            let js = /js$/i.test(name);
            let es = document.getElementsByTagName(js ? 'script' : 'link');
            for (let i = 0; i < es.length; i++)
                if (es[i][js ? 'src' : 'href'].indexOf(name) != -1) return true;
            return false;
        }

        return new Promise((resolve, reject) => {
            if (!isInclude(src.split("/")[src.split("/").length - 1])) {
                // 创建script标签，引入外部文件
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = src;
                document.getElementsByTagName('head')[0].appendChild(script)
                // 引入成功
                script.onload = function () {
                    resolve();
                    console.log('js资源已加载成功了')
                }
                // 引入失败
                script.onerror = function () {
                    reject();
                    console.log('js资源加载失败了')
                }
            } else {
                resolve();
            }
        })
    },
}