import { notification } from 'ant-design-vue';
import { delWaitResponseTask } from '@/utils/storage.js'
/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * 生成不重复的uuid
 * @param {string} type
 */
export function generateUUID(type = "default") {
  const timestamp = new Date().getTime();
  let uuid = "";
  switch (type) {
    case "diff":
      uuid = "diff_" + timestamp;
      break;
    case "enrichment":
      uuid = "enrichment_" + timestamp;
      break;
    case "default":
      uuid = timestamp;
      break;
  }
  return uuid;
}

/**
 * 存储异步任务列表
 * @param {string} type
 * @param {string} task_id
 * @param {string} meta
 * @param {object} sample_pms
 * {
 *    type:"差异分析",
 *    task_id:"diff_xxxx",
 *    start_time:"2020-01-01 12:00:22", //传时间戳过来
 *    end_time:"",                      //时间戳
 *    run_time:"",                      //通过时间戳计算
 *    status:" Waiting/Running/Successed/Failed",
 *    meta:{},                            //不同类型可能用到的不同结构数据对象
 *    sample_pms:{},                     // 用于筛选结果的sample select的参数
 *    result:{}                         //成功返回的数据
 *    error:null                       //错误返回的信息
 * }
 */
export function syncTaskListStorage(type, task_id, meta,sample_pms) {
  const data = {
    type: type,
    task_id: task_id,
    start_time: new Date().getTime(),
    end_time: "",
    run_time: "",
    status: "Waiting",
    meta: meta,
    sample_pms: sample_pms,
    result: {},
    error:null
  }

  let syncTaskList = window.localStorage.getItem("sync_task_list");

  if (syncTaskList === null) {
    syncTaskList = [data];
  } else {
    syncTaskList = JSON.parse(syncTaskList);
    syncTaskList.push(data);
  }

  window.localStorage.setItem("sync_task_list", JSON.stringify(syncTaskList));
}

/**
 * 异步任务列表修改状态
 * @param {*} data 
 */
export function syncTaskListStorageStatusChange(data) {
  let syncTaskList = window.localStorage.getItem("sync_task_list");
  if (syncTaskList === null) return;

  let taskList = JSON.parse(syncTaskList);
  taskList.forEach(item => {
    if (item.task_id !== data.uid) return;

    if (data.status === "Successed" || data.status === "Failed") {
      item.end_time = new Date(data.end_time).getTime();
      item.run_time = item.end_time - item.start_time;
    }

    item.status = data.status;

    if(data.status === "Failed"){
      notification.error({
        message: `${item.type} 任务执行失败`,
        description: `任务编号：${item.task_id}  执行失败！请查看右下角Task`,
        duration: 0
      });
    }
  });
  window.localStorage.setItem("sync_task_list", JSON.stringify(taskList));
}

/**
 * 异步任务列表添加结果
 * @param {*} data 
 */
export function syncTaskListStorageAddResult(data) {
  let syncTaskList = window.localStorage.getItem("sync_task_list");
  if (syncTaskList === null) return;

  let taskList = JSON.parse(syncTaskList);
  taskList.forEach(item => {
    if (item.task_id !== data.task.uid) return;

    item.result = {
      files:data.files,
      path:data.path
    };

    notification.success({
      message: `${item.type} 任务已完成`,
      description: `任务编号：${item.task_id}  已完成！请查看右下角Task！`,
      duration: 0,
    });
  });
  window.localStorage.setItem("sync_task_list", JSON.stringify(taskList));
}

/**
 * 异步任务列表添加错误信息
 * @param {*} data
 */
export function syncTaskListStorageAddError(data) {
  let syncTaskList = window.localStorage.getItem("sync_task_list");
  if (syncTaskList === null) return;

  let taskList = JSON.parse(syncTaskList);
  taskList.forEach(item => {
    if (item.task_id !== data.task.uid) return;

    item.error = data.task.fail_out;
  });
  window.localStorage.setItem("sync_task_list", JSON.stringify(taskList));
}

/**
 * 异步任务超时(暂时无用)
 * @param {*} data 
 */
export function syncTaskListStorageTaskTimeOut(data) {
  let syncTaskList = window.localStorage.getItem("sync_task_list");
  if (syncTaskList === null) return;

  let taskList = JSON.parse(syncTaskList);
  taskList.forEach(item => {
    if (item.task_id !== data.id) return;

    item.end_time = new Date().getTime();
    item.run_time = item.end_time - item.start_time;
    item.status = "TIMEOUT";
    item.result = data;

    notification.error({
      message: `${item.type} 任务超时`,
      description: `任务编号：${data.id}  已超时！请查看右下角Task`,
      duration: 0
    });
  });
  window.localStorage.setItem("sync_task_list", JSON.stringify(taskList));
}

/**
 * 异步任务列表移除任务
 * @param {string} taskId 
 */
export function syncTaskListStorageRemoveTask(taskId) {
  let syncTaskList = window.localStorage.getItem("sync_task_list");
  if (syncTaskList === null) return;

  const taskList = JSON.parse(syncTaskList).filter(item => item.task_id !== taskId);

  //从等待任务列表移除
  delWaitResponseTask(taskId);

  window.localStorage.setItem("sync_task_list", JSON.stringify(taskList));
}